import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { AppAndTenantConfig } from "./models/tenant/tenant-info";
import config from "./env/environment";

export const doLogin = async (keycloak: Keycloak): Promise<any> => {
	return new Promise((resolve) => {
		keycloak
			.login()
			.then(function (auth) {
				resolve("logged");
			})
			.catch((ex) => {
				console.error("Error on doLogin", ex)
				throw "Keycloak Login Failed";
			});
	});
};

export const doInit = async (
	config: KeycloakConfig,
	options: KeycloakInitOptions
): Promise<Keycloak> => {
	return new Promise((resolve) => {
		const keycloak = new Keycloak(config);
		if (config.clientId == null || config.clientId == undefined || config.clientId == '') {
			resolve(({} as Keycloak));
		}
		keycloak
			.init(options)
			.then(function (auth) {
				resolve(keycloak);
			})
			.catch((ex) => {
				console.error("Error on doInit", ex)
				throw "Keycloak Init Failed";
			});
	});
};
export const keycloakInitOptions: KeycloakInitOptions = {
	onLoad: "check-sso",
	checkLoginIframe: false,
};

export const REGEX = (app: string, tenant: string) => {
	return [
		`\/${tenant}\/${app}\/inscriptions\/form(\/?)(.)*`,
		`\/choose-tenant\/?(.)*`,
		`\/${tenant}\/${app}\/suite-header\/request-reset-password\/?(.)*`,
		`\/${tenant}\/${app}\/requests\/special-requests(\/?)(.)*`,
		`\/${tenant}\/${app}\/curriculum\/?(.)*`,
		`\/${tenant}\/choose-app\/?(.)*`,
	];
};

export const buidldKeycloakConfig = (appAndTenantInfo: AppAndTenantConfig): KeycloakConfig => {
	const { tenant, client } = appAndTenantInfo;
	return { url: config.keycloakHost, realm: tenant, clientId: client }
}
