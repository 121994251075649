import {
  RegisterApplicationConfig,
  registerApplication,
  start,
} from "single-spa";
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
  WithLoadFunction,
} from "single-spa-layout";

import microfrontendLayout from "./microfrontend-layout.html";

export default function buildApplications(props: any) {
  const routes = constructRoutes(microfrontendLayout);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });

  applications.map(function (app) {
    app.customProps = props;
    return app;//validCustomActiveWhen(app);
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
}

/*const validCustomActiveWhen = (
  app: RegisterApplicationConfig<{}> & WithLoadFunction
): RegisterApplicationConfig<{}> & WithLoadFunction => {
  switch (app.name) {
    case "@zb/zabud-plus-fanz":
      app.activeWhen = [
        (location) => {
          const pathSegments = location.pathname.split("/");
          return (
            pathSegments.includes(ZabudSuiteCrm.app?.toLocaleLowerCase()) &&
            TENANTS.some((tenant) => pathSegments.includes(tenant))
          );
        },
      ];
      break;

    default:
      break;
  }
  return app;
};
*/